/* stylelint-disable max-nesting-depth */
/* stylelint-disable scss/selector-no-union-class-name */
@import 'styles/mixins';

.eduExp {
  &_content {
    @include sm {
      max-height: 55vh;
      overflow: auto;

      @media screen and (max-height: 680px) {
        max-height: 43vh;
      }

      // &::-webkit-scrollbar {
      //   width: 4px;
      // }

      // &::-webkit-scrollbar-thumb {
      //   background: #b8b8b8c8;
      //   border-radius: 10px;
      // }

      // &::-webkit-scrollbar-thumb:hover {
      //   background: #b8b8b8;
      // }
    }
  }

  &_icon {
    width: 36px;
    height: 36px;

    @include sm {
      width: 32px;
      height: 32px;
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
  }

  &_title {
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 32px 0;
  }

  &_subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #000;
    text-align: center;

    @include sm {
      text-align: left;
    }
  }

  &_steps {
    flex-shrink: 0;
  }

  &_item {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  &_input {
    :global {
      .ant-input::placeholder {
        font-weight: 300 !important;
        color: #646566 !important;
      }
    }

    input,
    textarea {
      font-size: 16px;
    }
  }

  &_date {
    display: flex;
    gap: 8px;

    :global {
      .ant-picker-input > input {
        font-size: 16px !important;
      }

      input::placeholder {
        font-size: 16px;
        color: #646566 !important;
      }

      @include sm {
        .ant-picker-input > input {
          font-size: 16px;
        }
      }
    }
  }

  &_label {
    margin-bottom: 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #121212;
  }

  &_optional {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #646566;
    text-transform: capitalize;
    letter-spacing: 0.28px;
  }

  &_switch {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #4b4c4d;
    text-align: center;
    letter-spacing: 0.28px;

    :global {
      .ant-switch {
        background: #f4f5f6;
        border-radius: 6px;
      }

      .ant-switch:hover:not(.ant-switch-disabled) {
        background: #f4f5f6;
      }

      .ant-switch.ant-switch-checked {
        background: #1c70ee !important;
      }
    }
  }

  &_radioGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_jobFunction {
    margin-bottom: 0 !important;
  }

  &_footer {
    display: flex;
    gap: 13px;
    justify-content: space-between;
    margin-top: 0;

    @include sm {
      padding-top: 12px;
      margin-top: 0;
    }
  }

  &_button {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 10px 50px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    background: #fff;
    border: 0;
    border-radius: 30px;
    outline: none;

    @include sm {
      padding: 0;
    }
  }

  &_button_disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &_back {
    color: #515151;
    border: 1px solid #e4e4e4;

    @include sm {
      flex: 2;
    }
  }

  &_backBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &_continue {
    color: #fff;
    background: #2378e5;

    @include sm {
      flex: 3;
    }
  }

  &_error {
    color: red;
  }

  &_noExp {
    height: 160px;

    @include sm {
      height: 30vh;
    }
  }
}

.schoolDropDown {
  max-height: 300px;
  padding: 4px;
  overflow: auto;
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  text-transform: capitalize;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);

  @include sm {
    max-height: 200px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b8b8b8c8;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b8b8b8;
  }

  &.noData {
    padding: 0;
    box-shadow: none;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    padding: 0 8px;
    line-height: 1;
    border-radius: 2px;

    &:hover {
      cursor: pointer;
      background: var(--brand-1-f-5-f-7-fb, #f5f7fb);
    }
  }
}
