/* stylelint-disable selector-max-class */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable scss/selector-no-union-class-name */

.desired {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.desiredContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
}

.welcome {
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: #000;
  text-align: center;
}

.content {
  position: relative;
  z-index: 12;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 600px;
  padding: 40px 20px;
  margin: 0 auto;
  background: #fff;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121212;
}

.text {
  margin-top: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #515151;
  letter-spacing: 0.32px;
}

.safeInfo {
  margin: 0;
}

.form {
  margin-top: 24px;

  &_item {
    margin-bottom: 40px;
  }

  &_label {
    margin-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #000;
  }

  .radioGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 40px;
  }

  &_optional {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #646566;
    text-transform: capitalize;
    letter-spacing: 0.28px;
  }

  &_salary {
    display: flex;
    flex-flow: column wrap;
    gap: 8px;
  }

  &_salaryType {
    flex-basis: auto;
  }

  &_salaryRange {
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    gap: 8px;
  }

  &_minSalary {
    flex: 1;
  }

  &_maxSalary {
    flex: 1;
  }

  :global {
    .yolo-custom-radio-label {
      font-size: 14px !important;
    }
  }
}

.submitWrapperFixed {
  width: 100%;
  height: 100px;
  pointer-events: none;
}

.submitWrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  display: flex;
  width: 100%;
  padding: 20px 10px;
  background: #fff;
}

.submitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  padding: 0 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
  background: #1c70ee;
  border: 0;
  border-radius: 12px;
  outline: none;
}

.submitBtnDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}
