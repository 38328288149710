/* stylelint-disable selector-max-class */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable scss/selector-no-union-class-name */

.desired {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.desiredContainer {
  display: flex;
  flex: 1;
  padding: 16px;
  background-color: #fff;
}

.leftBanner {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 584px;
  min-height: 100%;
  background: #fafcff;
  border-radius: 16px;
}

.leftBannerImage {
  display: flex;
  flex: 1 0 0;
  align-items: center;
  align-self: stretch;
  padding: 0 56px;

  img {
    width: 100%;
    height: auto;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 32px 0 48px;
}

.welcome {
  width: 100%;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #00112b;
  text-align: center;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  z-index: 12;
  display: flex;
  flex-direction: column;
  width: 460px;
  min-height: 600px;
  padding: 32px 32px 24px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #f4f5f6;
  border-radius: 20px;
  box-shadow: 0 8px 14px -2px rgba(162, 183, 203, 0.15);
}

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121212;
}

.text {
  margin-top: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #515151;
  letter-spacing: 0.32px;
}

.safeInfo {
  margin: 40px 0;
}

.form {
  margin-top: 40px;

  &_item {
    margin-bottom: 40px;
  }

  &_label {
    margin-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #000;
  }

  .radioGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }

  &_optional {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #646566;
    text-transform: capitalize;
    letter-spacing: 0.28px;
  }

  &_salary {
    display: flex;
    flex-flow: column wrap;
    gap: 8px;
  }

  &_salaryType {
    flex: 1;
  }

  &_salaryRange {
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    gap: 8px;
  }

  &_minSalary {
    flex: 1;
  }

  &_maxSalary {
    flex: 1;
  }

  &_submit_left {
    display: flex;
    margin-top: 40px;
  }

  &_submit_right {
    display: flex;
    margin-top: 40px;
  }

  &_submitBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    padding: 0 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
    background: #1c70ee;
    border: 0;
    border-radius: 12px;
    outline: none;
  }

  &_submitBtn_disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
