/* stylelint-disable scss/selector-no-union-class-name */
@import 'styles/mixins';

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
}

.commonHeader {
  background: #fff;
}

.container {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;

  @include sm {
    display: flex;
    flex: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
  }
}

.safeInfo {
  padding: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #3b3c3c;
  text-align: center;
  background: #f8f9fa;
  border-radius: 12px;

  &_icon {
    position: relative;
    bottom: -3px;
    flex-shrink: 0;
    margin-right: 4px;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  a {
    color: #1c70ee;
    text-decoration: underline;
  }

  @include sm {
    padding: 16px 20px 24px;
    text-align: left;
  }
}
