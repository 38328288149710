/* stylelint-disable scss/selector-no-union-class-name */
@import 'styles/mixins';

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: SSFProDisplay !important;
  background: #f7f8fa;

  * {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: SSFProDisplay !important;
  }
}

.commonHeader {
  background: #fff;
}

// Common header
.header {
  position: sticky;
  top: 0;
  z-index: 2000;
  width: 100%;
  background: #fff;

  .headerContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    padding: 0 48px;
    background: #fff;
  }

  .logo {
    width: 96px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .headerRight {
    display: flex;
  }

  .link {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 12px 20px;
    margin-left: 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #000;
    cursor: pointer;
    background: #f8f9fa;
    border-radius: 12px;
  }

  .link:hover {
    background: #f4f5f6;
  }
}

.desiredHeader {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include sm {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
    background: #fff;
  }
}

.desiredHeaderFixed {
  display: none;

  @include sm {
    position: relative;
    z-index: -1;
    display: inline;
  }
}

.desiredHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #121212;

  @include sm {
    height: auto;
    padding: 9px 12px 9px 16px;
  }
}

.desiredHeaderLogo {
  width: 48px;
  height: 48px;

  @include sm {
    width: 40px;
    height: 40px;
  }

  a {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.desiredHeaderRight {
  display: flex;
}

.desiredHeaderHiring {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 12px 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #000;
  cursor: pointer;
  background: #f8f9fa;
  border-radius: 12px;

  @include sm {
    gap: 4px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    padding: 12px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
  }
}

.desiredHeaderHiring:hover {
  background: #f4f5f6;
}

.desiredHeaderLang {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 12px 20px;
  margin-left: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #000;
  cursor: pointer;
  background: #f8f9fa;
  border-radius: 12px;
}

.desiredHeaderLang:hover {
  background: #f4f5f6;
}

.desiredHeaderLangList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 230px;
  padding: 8px;
  margin-top: 10px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);
}

.desiredHeaderLangItem {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 18px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #111212;
  cursor: pointer;
  border-radius: 10px;
}

.desiredHeaderLangActive {
  color: #1c70ee;
  background: #f5f9ff;
}

.desiredHeaderLangItem:hover {
  color: #1c70ee;
  background: #f5f9ff;
}

.container {
  position: relative;
  flex: 1;
  height: 100%;

  @include sm {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 100vh;
    background-color: #fff;
  }
}

.safeInfo {
  padding: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #3b3c3c;
  text-align: center;
  background: #f8f9fa;
  border-radius: 12px;

  &_icon {
    position: relative;
    bottom: -3px;
    flex-shrink: 0;
    margin-right: 4px;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  a {
    color: #3b3c3c;
    text-decoration: underline;
  }

  @include sm {
    padding: 14px 20px;
    text-align: left;
  }
}

// footer layout
.footer {
  margin-bottom: 48px;
  background: #f7f8fa;

  @include sm {
    margin: 0;
  }

  &_container {
    border-top: 1px solid #e8e8ea;

    @include sm {
      border: 0;
    }
  }

  &_content {
    display: flex;
    justify-content: space-between;
    max-width: 1136px;
    padding: 0 10px;
    margin: 0 auto;
    font-size: 14px;
    color: #7d7d7d;

    @include sm {
      padding: 24px 20px;
      color: #b8b8b8;
    }
  }

  &_copyright_text {
    padding: 20px 0;
    font-size: 14px;
    text-align: center;

    @include sm {
      padding: 0;
    }
  }

  &_change_lan {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    height: 58px;
    cursor: pointer;

    @include sm {
      display: none;
    }
  }

  &_current_lan {
    margin: 0 10px;
    color: #b8b8b8;
  }

  &_choose_lan {
    position: absolute;
    right: -25px;
    bottom: 50px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 147px;
    padding: 4px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    transition: visibility 0.2s ease-in-out;
  }

  &_choose_item {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-content: center;
    align-items: center;
    align-self: stretch;
    height: 36px;
    padding: 0 8px;
    font-size: 14px;
    color: #121212;

    &:hover {
      background-color: #f5f7fb;
    }
  }
}

.jobFunction {
  &_selector {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    padding: 10px 12px;
    margin-bottom: 16px;
    cursor: pointer;
    background: #fff;
    border: 1.2px solid #ebeef0;
    border-radius: 12px;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

    &:hover {
      border: 1px solid #4096ff;
    }
  }

  &_placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 300 !important;
    line-height: normal;
    color: #646566;
  }

  &_value {
    display: block;
    width: calc(100% - 20px);
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_arrow {
    position: absolute;
    top: 12px;
    right: 10px;
  }
}

.loadingButton {
  &_spinner {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    min-width: 30px;
  }

  &_disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &_icon {
    display: inline-block;
    animation: animation-spin 1.4s linear infinite;
  }
}

@keyframes animation-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mobileModal {
  :global {
    .ant-drawer-content-wrapper {
      overflow: hidden;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    .ant-drawer-body {
      padding: 32px 20px 10px !important;
    }
  }

  &_closed {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.resumeModal {
  :global {
    .ant-modal-content {
      border-radius: 16px !important;
    }
  }
}
