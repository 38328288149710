@mixin flex($direction: row, $justify-content: space-between) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
}

@mixin transition($ms: 0ms, $timingFunction: linear) {
  transition: $ms all $timingFunction;
}

// Device sizes
// Very small devices
@mixin xsm {
  @media (max-width: 400px) {
    @content;
  }
}
// Small devices
@mixin sm {
  @media (max-width: 576px) {
    @content;
  }
}

// Medium small devices (Ipad)
@mixin msd {
  @media (max-width: 799px) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: 800px) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin metaTag {
  color: transparent;
  height: 1px;
  display: none;
}

@mixin text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
@mixin line-clamp-with-line-height($line-count, $line-height) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // Specify the number of lines to be displayed
  -webkit-line-clamp: $line-count;
  line-height: $line-height;
  white-space: normal;
}
@mixin respond-to($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $breakpoint == large {
    @media (min-width: 1200px) {
      @content;
    }
  }
}
