/* stylelint-disable scss/selector-no-union-class-name */
@import 'styles/mixins';

.cropAvatar {
  &_header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &_title {
    padding-top: 16px;
    margin: 0 auto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #000;
    text-align: center;

    @include sm {
      font-size: 24px;
    }
  }

  &_content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    canvas {
      border-radius: 20px;
    }
  }

  &_action {
    display: flex;
    gap: 12px;
    align-items: center;
    max-width: 300px;
    margin: 24px auto;
    color: #111212;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

  }

  &_item {
    padding-bottom: 2px;
  }

  &_label {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  &_slider {
    flex: 1;


  }

  &_footer {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding-bottom: 8px;

    >div {
      flex: 1;
    }

    button {
      display: flex;
      flex-shrink: 0;
      gap: 4px;
      align-items: center;
      justify-content: center;
      width: 100px;
      padding: 10px 24px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      font-feature-settings: 'clig' off, 'liga' off;
      line-height: normal;
      border-radius: 4px;
      outline: none;
    }
  }

  &_cancel {
    color: #121212;
    background: transparent;
    border: 1px solid #e4e4e4;
  }

  &_confirm {
    color: #fff;
    background: #2378e5;
    border: 0;
  }

  :global {
    .ant-slider-rail {
      background-color: #b8b8b8;
    }

    .ant-slider-track {
      background-color: #0066FF !important;
    }

    .ant-slider-handle::after {
      width: 16px !important;
      height: 16px !important;
      inset-block-start: -3px !important;
      box-shadow: 0 0 0 1px rgba(0, 102, 255, 0.5) !important;
    }
  }
}