/* stylelint-disable scss/selector-no-union-class-name */
@import 'styles/mixins';

.changeAvatar {
  &_title {
    margin-bottom: 4px;
  }

  &_subtitle {
    padding-top: 16px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #000;
    text-align: center;
  }

  &_desc {
    padding: 0 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #4b4c4d;
    text-align: center;
  }

  &_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &_upload_btn {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    background: #f8f9fa;
    border: none;
    border-radius: 100px;
    outline: none;
  }

  &_upload_main {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #2378e5;
    text-align: center;
    text-transform: capitalize;
  }

  &_upload_tips {
    margin-top: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #4b4c4d;
  }

  &_avatar {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 340px;
    padding: 12px;
    margin: 0 auto;
    margin-bottom: 34px;
    background: #fff;
    border: 1px solid #ebeef0;
    border-radius: 20px;
    box-shadow: 0 5px 8px -2px rgba(162, 183, 203, 0.1);
  }

  &_or {
    flex-shrink: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #b8b8b8;
    text-align: center;
  }

  &_avatar_list {
    display: flex;
    gap: 8px;
    align-items: center;

    @include sm {
      gap: 15px;
      width: 100%;
      height: 60px;
      overflow: auto;
    }
  }

  &_avatar_item {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 50%;

    @include msd {
      width: 44px;
      height: 44px;
    }

    :global {
      .ant-avatar {
        width: 100% !important;
        height: 100% !important;
        border: none !important;
      }
    }
  }

  &_avatar_img {
    width: 100%;
    height: 100%;
    border: 0;
  }

  &_avatar_active {
    border: 2px solid #2378e5;
  }

  &_avatar_checked {
    position: absolute;
    right: 2px;
    bottom: -2px;
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: center;

    @include msd {
      > div {
        width: 100%;
      }
    }
  }

  &_confirm {
    padding: 0 48px !important;

    // @include sm {
    //   width: 100%;
    //   font-size: 16px;
    //   border-radius: 30px;
    // }
  }

  // &_confirm_disabled {
  //   cursor: not-allowed;
  //   opacity: 0.5;
  // }
}

/* stylelint-disable-next-line selector-class-pattern */
.changeAvatar_avatar_item_selected {
  border: 2px solid #1c70ee;
}
